import Modal from "react-modal";
import React, { useEffect, useState } from "react";
import Styles from "./complete.module.scss";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Inputs, props } from "./types";
import { Services } from "Service/service";
import { Error, warningSwal } from "Reusable/utils/Toast";
import Select from "react-select";
import { AiOutlineClose } from "react-icons/ai";
import swal from "sweetalert";
Modal.setAppElement("#root");

const DemoForm: React.FC<props> = ({ modal, setModal, getDetails, id }) => {
    const {
        handleSubmit,
        reset,
        register,
        setValue,
        control,
        watch,
        formState: { errors },
    } = useForm<Inputs>({ defaultValues: { is_partial_completed: false } });
    const token = {
        Authorization: `token ${localStorage.getItem("learn2readToken")}`,
    };
    const [lessonPlan, setLessonPlan] = useState([]);
    const [partial, setPartial] = useState([]);
    const [selectedPlan, setSelectedPlan] = useState([]);
    const [loading, setLoading] = useState(false);
    const getLessonPlan = async () => {
        setLoading(true);
        console.log(getDetails.data);
        try {
            let body = {
                LessonPlans_Batch: getDetails.data.LessonPlans_Batch,
                CourseID: getDetails.data.CourseID,
            };
            const getCourseDetails = await Services.get_Lesson_Plan(
                "POST",
                JSON.stringify(body),
                token
            );
            if (getCourseDetails.Status === 1) {
                setLessonPlan(
                    getCourseDetails.PendingLessonPlans.map((item: any) => ({
                        label: item.PlanNumber,
                        value: item.PlanNumber,
                    }))
                );
                setPartial(
                    getCourseDetails.PendingLessonPlans.map((item: any) => ({
                        label: item.PlanNumber,
                        value: item.PlanNumber,
                    }))
                );
            }
        } catch (err: any) {
            console.log(err);
            warningSwal("Error", "Something went wrong please try again");
        } finally {
            setLoading(false);
        }
    };
    const findNonCommonObjects = (arr1: any, arr2: any) => {
        const arr2Set = new Set(arr2.map((obj: any) => JSON.stringify(obj)));
        return arr1.filter((obj: any) => !arr2Set.has(JSON.stringify(obj)));
    };

    useEffect(() => {
        getLessonPlan();
    }, []);
    const watchData = watch();
    // CLOSE FORM
    const closeModal = () => {
        setModal(false);
        reset();
    };

    // FORM SUBMIT
    const onSubmit: SubmitHandler<Inputs> = async (formdata) => {
        setLoading(true);
        let Body: any = {
            CourseID: getDetails?.data?.CourseID,
            ScheduleClassID: getDetails?.data?.ScheduleClassID,
            Students: getDetails?.data?.Students,
            FeePerHour: getDetails?.data?.FeePerHour,
            BatchName: getDetails?.data?.BatchName,
            Scheduled_StartTime: getDetails?.data?.Scheduled_StartTime,

            Duration: getDetails?.data?.Duration,
            ClassDuration: getDetails?.data?.ClassDuration,
            BatchSize: getDetails?.data?.BatchSize,
            Scheduled_StartDate:
                getDetails?.data?.Scheduled_StartDate &&
                getDetails?.data?.Scheduled_StartDate != "NA" &&
                String(getDetails?.data?.Scheduled_StartDate).split("T")[0],
            BatchID: getDetails?.data?.BatchID,
            LessonPlans_Batch: getDetails?.data?.LessonPlans_Batch,
            PlanNumber_Batch: getDetails?.data?.PlanNumber_Batch,
            PartiallyCompleted: { IsPartiallyCompleted: 0 },
            Comments: formdata.Comments,
            NewTopicDone: formdata.NewTopicDone,

            TutorEmailID: getDetails?.data?.TutorEmailID,
            LessonPlans_Class: selectedPlan.map((item: any) => item.value),
        };
        if (watchData.is_partial_completed) {
            Body = {
                ...Body,
                PartiallyCompleted: {
                    IsPartiallyCompleted: 1,
                    Slide: formdata.SlideNumber,
                    LessonPlan: formdata.Lesson.value,
                },
            };
        }
        try {
            const CompleteClass = await Services.Complete_Class(
                "POST",
                JSON.stringify(Body),
                token
            );
            setLoading(false);
            if (CompleteClass.Status === 1) {
                swal("Completed", CompleteClass.Message, "success");
                setModal(false);
                getDetails?.details(id);
            } else {
                warningSwal("Warning", CompleteClass?.Message);
            }
        } catch (err) {
            setLoading(false);
            console.log(err);
            warningSwal("Error", "Something went wrong please try again");
        }
    };
    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            minHeight: state.hasValue ? "3.3rem" : "3rem", // Adjust the height based on your preference
        }),
    };
    const onPlanSelect = (data: any) => {
        setPartial(findNonCommonObjects(lessonPlan, data));
        setValue("Lesson", []);
        setSelectedPlan(data);
    };
    return (
        <Modal
            isOpen={modal}
            contentLabel="Modal"
            className={Styles["modal"]}
            onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["form-wrapper"]}>
                <button onClick={closeModal}>
                    <AiOutlineClose />
                </button>
                <h2>Complete Class</h2>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className={Styles["form-control"]}>
                        <label htmlFor="">Lesson Completed :</label>
                        <Controller
                            name="LessonPlans_Class"
                            control={control}
                            defaultValue={""}
                            rules={{
                                required:
                                    selectedPlan.length > 0 ||
                                    watchData.is_partial_completed
                                        ? false
                                        : "This field is required",
                            }}
                            render={({ field }) => (
                                <>
                                    <Select
                                        {...field}
                                        options={lessonPlan}
                                        styles={customStyles}
                                        isSearchable={false}
                                        onChange={onPlanSelect}
                                        value={selectedPlan}
                                        placeholder="Select plan"
                                        isMulti
                                        isLoading={loading}
                                        closeMenuOnSelect={false}
                                        className={Styles["select"]}
                                        classNames={{
                                            control: (state) =>
                                                Styles["control"],
                                            indicatorSeparator: () =>
                                                Styles["indicator"],
                                            valueContainer: () =>
                                                Styles["valueContainer"],
                                            input: () => Styles["input"],
                                            indicatorsContainer: () =>
                                                Styles["indicationContainer"],
                                            singleValue: () =>
                                                Styles["singleValue"],
                                            option: () => Styles["option"],
                                            menu: () => Styles["menu"], // class for the options container
                                            menuList: () => Styles["menuList"],
                                        }}
                                    />
                                    <p></p>
                                    {errors.LessonPlans_Class && (
                                        <p className={Styles["error-msg"]}>
                                            This is required
                                        </p>
                                    )}
                                </>
                            )}
                        />
                    </div>
                    {watchData.is_partial_completed && (
                        <div className={Styles["form-control"]}>
                            <label htmlFor="">Partial Plan No :</label>
                            <Controller
                                name="Lesson"
                                control={control}
                                defaultValue={""}
                                rules={{
                                    required: "This field is required",
                                }}
                                render={({ field }) => (
                                    <>
                                        <Select
                                            {...field}
                                            options={partial}
                                            styles={customStyles}
                                            isSearchable={false}
                                            placeholder="Select plan"
                                            isLoading={loading}
                                            closeMenuOnSelect={true}
                                            className={Styles["select"]}
                                            classNames={{
                                                control: (state) =>
                                                    Styles["control"],
                                                indicatorSeparator: () =>
                                                    Styles["indicator"],
                                                valueContainer: () =>
                                                    Styles["valueContainer"],
                                                input: () => Styles["input"],
                                                indicatorsContainer: () =>
                                                    Styles[
                                                        "indicationContainer"
                                                    ],
                                                singleValue: () =>
                                                    Styles["singleValue"],
                                                option: () => Styles["option"],
                                                menu: () => Styles["menu"], // class for the options container
                                                menuList: () =>
                                                    Styles["menuList"],
                                            }}
                                        />
                                        <p></p>
                                        {errors.Lesson && (
                                            <p className={Styles["error-msg"]}>
                                                This is required
                                            </p>
                                        )}
                                    </>
                                )}
                            />
                        </div>
                    )}
                    {watchData.is_partial_completed && (
                        <div className={Styles["form-control"]}>
                            <label htmlFor="Comments">
                                Slide Number <span>:</span>
                            </label>
                            <div className={Styles["form-control-one"]}>
                                <input
                                    type="number"
                                    placeholder="Slide number"
                                    {...register("SlideNumber", {
                                        required: true,
                                    })}
                                />
                                {errors.SlideNumber && (
                                    <span className={Styles["error"]}>
                                        Slide Number is required
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                    <div className={Styles["form-control"]}>
                        <label htmlFor="">
                            Topic taken today <span>:</span>
                        </label>
                        <div className={Styles["form-control-one"]}>
                            <input
                                type="text"
                                {...register("NewTopicDone", {
                                    required: "true",
                                })}
                            />
                            {errors.NewTopicDone && (
                                <span style={{ color: "red" }}>
                                    This is required
                                </span>
                            )}
                        </div>
                    </div>
                    <div className={Styles["form-control"]}>
                        <label htmlFor="Comments">
                            Description of Topic <span>:</span>
                        </label>

                        <textarea
                            {...register("Comments", {
                                required: false,
                            })}></textarea>
                    </div>
                    <div className={Styles["radio-buttons"]}>
                        <label>
                            <Controller
                                name="is_partial_completed"
                                control={control}
                                defaultValue={1}
                                render={({ field }) => (
                                    <>
                                        <input
                                            type="checkbox"
                                            {...field}
                                            value={2}
                                        />
                                        <span className={Styles["span"]}>
                                            {" "}
                                            Partially Completed
                                        </span>
                                    </>
                                )}
                            />
                        </label>
                    </div>
                    {/* eslint-disable-next-line */}

                    <div className={Styles["buttons"]}>
                        <button
                            type="submit"
                            className={Styles["submit"]}
                            disabled={loading}>
                            {loading ? "...please wait" : "Complete"}
                        </button>
                        <button
                            type="button"
                            onClick={closeModal}
                            className={Styles["close"]}>
                            Cancel
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    );
};
export default DemoForm;
